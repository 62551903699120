.calButton {
  padding: 0;
  background-color: transparent;
  margin-right: 15px;
}

.calButton img {
  width: 45px;
  height: 45px;
}

.calButton:hover {
  background-color: transparent;
}

.calButton:hover img {
  -webkit-filter: drop-shadow(2px 2px 2px #222);
  filter: drop-shadow(2px 2px 2px #222);
}

.reorder {
  background: #f1f1f1;
  overflow: scroll;
  min-height: 100vh;
}
