.reportTabs {
  flex-grow: 1;
  display: flex;
  height: auto;
  background-color: #fff;
}

.printTabs {
  flex: 0 0 100%;
}

.printTab {
  margin-top: 35px;
}
