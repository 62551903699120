.legend {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 15px;
  min-height: 60px;
  position: relative;
}

.wrapper {
  display: flex;
  flex: 1 0 24%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 5px;
}

@media screen and (min-width: 1024px) {
  .wrapper {
    flex: 1 1 0;
  }
}

.title {
  font-size: 10px;
  text-align: center;
}

.close,
.toggleLegendButton {
  cursor: pointer;
  padding: 6px 12px;
  font-size: 1.2rem;
}

.close {
  position: absolute;
  right: 0;
  top: 0;
}
