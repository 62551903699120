/* Global Button Styles */
.customButton {
  background: none;
  position: relative;
  display: inline-block;
  padding: 14px 15px;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  height: auto;
  line-height: normal;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  letter-spacing: 0.08em;
  border-radius: 0;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.2), 0 1px 0 rgba(0, 0, 0, 0.2);
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
  z-index: 0;
  min-width: 260px;
  max-width: 270px;
  width: 100%;
  border: 2px solid #d2590f;
}

.customButton:after {
  content: '';
  position: absolute;
  height: 0;
  left: 50%;
  top: 50%;
  width: 150%;
  z-index: -1;
  -webkit-transition: all 0.75s ease 0s;
  -moz-transition: all 0.75s ease 0s;
  -o-transition: all 0.75s ease 0s;
  transition: all 0.75s ease 0s;
  background: #d2590f;
  -moz-transform: translateX(-50%) translateY(-50%) rotate(-25deg);
  -ms-transform: translateX(-50%) translateY(-50%) rotate(-25deg);
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(-25deg);
  transform: translateX(-50%) translateY(-50%) rotate(-25deg);
}

.customButton:hover {
  color: #fff;
  background: none;
  text-shadow: none;
  text-decoration: none;
}

.customButton:hover:after {
  height: 450%;
}
