.emptyState {
  margin: 100px;
}

.emptyState {
  margin-top: 50px;
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
}

.listItem {
  margin-bottom: 10px;
}

.section {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.reorderButton {
  border-radius: 4px;
  border: solid 1px #6c757d;
  display: inline-block;
  height: auto;
  line-height: 1.15;
  overflow: hidden;
  padding: 8px;
  text-overflow: ellipsis;
  white-space: normal;
  width: 100%;
}

.reorderButton:hover {
  background: #6c757d;
  color: #fff;
}

.orderedButton {
  border-color: #dc3545;
}

.orderedButton:hover {
  background: #dc3545;
}

.actionButton {
  flex: 100% 0 0;
}

.actionButton + .actionButton {
  margin-top: 24px;
}

@media  (min-width : 480px) {
  .actionButton {
    flex: auto 0 0;
  }

  .actionButton + .actionButton {
    margin-left: 24px;
    margin-top: 0;
  }
}

.actionText {
  margin-left: 4px;
}

.sectionHeading {
  font-size: 21px;
  margin-bottom: 24px;
  text-align: center;
  font-weight: bold;
}

@media only screen and (max-width : 1024px) {
  .sectionHeading {
    font-size: 18px;
  }
}

.calButton {
  position: fixed;
  top: 28px;
  right: 20px;
  z-index: 30;
  padding: 0;
  background-color: transparent;
  width: auto;
}

.calButton img {
  width: 45px;
  height: 45px;
}

.calButton:hover {
  background-color: transparent;
}

.calButton:hover img {
  -webkit-filter: drop-shadow(2px 2px 2px #222);
  filter: drop-shadow(2px 2px 2px #222);
}

.actions {
  display: flex;
  justify-content: flex-end;
  flex: 100% 0 0;
  flex-wrap: wrap;
}

.projects {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 15px;
  width: 100%;
  max-width: 1110px;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.69);
  border-radius: 5px;
}

.column {
  flex: 100% 0 0;
}

@media (min-width: 768px) {
  .column {
    flex: calc(50% - 15px) 0 0;
  }
}
