.header {
  padding: 24px 15px;
  background-color: #fff;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
}

.headerLine {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.search {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.projectsList {
  padding: 0 15px 30px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
}

@media only screen and (min-width: 768px) {
  .titleWrapper {
    width: auto;
    order: 0;
    margin-bottom: 0;
  }
}

.navWrapper {
  display: flex;
  width: 50%;
  justify-content: flex-end;
  flex: 0 0 150px;
}

@media only screen and (min-width: 768px) {
  .projectsList {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (min-width: 1200px) {
  .projectsList {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.noResults {
  margin-top: 24px;
  display: flex;
  justify-content: center;
}

.projectGrid {
  height: 100%;
  min-height: 120px;
  border-radius: 5px;
  padding: 15px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}

.projectsHeading {
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 24px;
  padding-right: 32px;
  position: relative;
}

@media only screen and (max-width : 768px) {
  .projectsHeading {
    font-size: 16px;
  }
}

.attributes {
  font-size: 0.8em;
  padding-left: 8px;
  margin-bottom: 24px;
}

.icon,
.reportDataTitle {
  margin-right: 4px;
}

.reportDataTitle {
  font-weight: 900;
}

.projectActions {
  display: flex;
  justify-content: flex-end;
}

.projectActionWrapper {
  margin: 0 10px;
}

.projectButton {
  display: inline-flex;
  border: solid 1px black;
  border-radius: 4px;
  text-align: center;
  height: 42px;
  align-items: center;
  justify-content: center;
  color: black;
  min-width: 45px;
}

.btnNew {
  border-color: #007bff;
}

.btnNew:hover {
  background: #007bff;
  color: #fff;
  text-decoration: none;
}

.btnReports,
.btnReports:hover {
  border-color: #6c757d;
  text-decoration: none;
}

.btnReports:hover {
  background: #6c757d;
  color: #fff;
}

.projectActionText {
  width: 45px;
  text-align: center;
  font-size: x-small;
  margin: 0;
}

.navButton {
  width: 54px;
  height: 54px;
  border-radius: 50%;
  text-align: center;
  padding: 0;
  font-size: 22pt;
  color: #000;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.navButton:hover {
  background: #fff;
  color: #000;
  box-shadow: #222 2px 2px 2px;
}

.projectsEditButton,
.projectsEditButton:visited {
  color: #000;
  margin-left: 10px;
  font-size: 20px;
}

.projectsEditButton:hover {
  color: #007bff;
  text-decoration: none;
}

.inputTitle {
  text-align: right;
  padding: 8px 10px;
  display: none;
}

@media only screen and (min-width : 576px) {
  .inputTitle {
    display: block;
  }
}

.searchInput {
  width: 100%;
}

@media only screen and (min-width : 576px) {
  .searchInput {
    max-width: 50%;
  }
}

.favoriteButton {
  background: none;
  border: 0;
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  right: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.favoriteButton:disabled {
  cursor: default;
  opacity: 1;
}

.favoriteButton:hover {
  color: #007bff;
}

.favoriteButton:active {
  background: none;
}

.favoriteButton:focus {
  outline: none;
}

.filter {
  padding: 8px 10px;
  justify-content: center;
  align-items: center;
}

.filterLabel {
  margin: 0;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.filterCheckbox {
  margin-right: 6px;
  width: 16px;
  height: 16px;
}
