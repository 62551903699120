.editReport {
  padding: 24px 15px 0;
  width: 100%;
  max-width: 1110px;
  margin: 0 auto;
}

.calendarWrapper,
.statusWrapper,
.rowWrapper {
  margin-bottom: 24px;
  width: 100%;
  flex: 0 0 100%;
}

.calendarWrapper,
.statusWrapper {
  display: inline-block;
}

.productWrapper,
.programWrapper {
  flex: 0 0 100%;
}

.attributeWrapper {
  flex: 0 0 100%;
}

@media (min-width: 768px) {
  .productWrapper,
  .programWrapper {
    flex: 0 0 49%;
  }

  .attributeWrapper {
    flex: 0 0 49%;
  }
}

@media (min-width: 992px) {
  .statusWrapper {
    flex: 0 0 24%;
  }

  .productWrapper,
  .programWrapper {
    flex: 0 0 37%;
  }
}

@media (min-width: 1200px) {
  .statusWrapper {
    flex: 0 0 16%;
  }

  .productWrapper,
  .programWrapper {
    flex: 0 0 41%;
  }
}

.formWrapper {
  background-color: #fff;
  border-radius: 5px;
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.label {
  margin-bottom: 4px;
}

.actionsWrapper {
  display: flex;
  justify-content: flex-end;
  flex: 0 0 100%;
}

@media screen and (max-width: 420px) {
  .actionsWrapper {
    flex-wrap: wrap;
  }
}

.actionButton {
  margin-right: 24px;
}

@media screen and (max-width: 420px) {
  .actionButton {
    margin-right: 0;
    margin-bottom: 24px;
  }
}

.actionText {
  margin-left: 4px;
}
