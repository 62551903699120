.keyFeatures {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
}

@media screen and (max-width: 768px) {
  .keyFeatures {
    grid-template-columns: 1fr;
    grid-gap: 15px;
  }
}

.keyFeaturesCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
  padding: 1em 1em 1em 2em;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

@media screen and (max-width: 480px) {
  .keyFeaturesCard {
    padding: 1em;
  }
}

.keyFeaturesCard:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.keyFeaturesTitle {
  font-weight: bold;
  margin-bottom: 15px;
  font-size: 1.4rem;
}

@media screen and (max-width: 480px) {
  .keyFeaturesTitle {
    margin-bottom: 8px;
  }
}

.keyFeaturesText {
  display: flex;
  justify-content: space-between;
  width: 80%;
  font-size: 1rem;
  margin-bottom: 8px;
}

.keyFeaturesItems {
  padding-left: 1em;
  margin-bottom: 15px;
}

@media screen and (max-width: 480px) {
  .keyFeaturesItems {
    padding-left: 0.5em;
  }
}

.keyFeaturesProgress {
  text-align: right;
}

.percent {
  margin-left: 8px;
  font-weight: 900;
}
