.homePage {
  height: 100vh;
  width: 100vw;
  background: #000 url('/src/images/background2.jpg') center;
  background-size: cover;
}

.wrapper {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-bottom: 40px;
  position: relative;
  background: rgba(14, 14, 14, .73);
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.heading {
  color: white;
  font-size: 30px;
  font-weight: normal;
  text-align: center;
  width: 100%;
}

@media only screen and (min-width : 768px) {
  .heading {
    font-size: 35px;
  }
}

.navItem {
  width: 100%;
  display: flex;
  margin-top: 30px;
  justify-content: center;
}

@media only screen and (min-width : 768px) {
  .navItem {
    width: calc(50% - 15px);
    justify-content: flex-end;
  }

  .navItem:nth-child(2n+1) {
    justify-content: flex-start;
  }
}

.contentWrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer {
  position: absolute;
  bottom: 15px;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.requestButton {
  line-height: normal;
  background: none;
  color: grey;
  text-transform: none;
}

.requestButton:hover {
  color: white;
  background: none;
}

.footer {
  position: absolute;
  bottom: 15px;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.requestButton {
  line-height: normal;
  background: none;
  color: grey;
  text-transform: none;
}

.requestButton:hover {
  color: white;
  background: none;
}
