.button {
  cursor: pointer;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 2.1;
  border-radius: 0.25rem;
  height: 2.85rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;;
  text-transform: uppercase;
}

.button:disabled {
  opacity: 0.65;
  cursor: default;
}

.variantInfo {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.variantInfo:not(:disabled):hover {
  background-color: #138496;
  border-color: #117a8b;
}

.variantOutlineInfo {
  color: #17a2b8;
  border-color: #17a2b8;
}

.variantOutlineInfo:not(:disabled):hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.variantDanger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.variantDanger:not(:disabled):hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.variantOutlineDanger {
  color: #dc3545;
  border-color: #dc3545;
}

.variantOutlineDanger:not(:disabled):hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.variantSuccess {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.variantSuccess:not(:disabled):hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.variantOutlineSuccess {
  color: #28a745;
  border-color: #28a745;
}

.variantOutlineSuccess:not(:disabled):hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.variantPrimary {
  background-color: #007bff;
  border-color: #007bff;
  color: #fff;
}

.variantPrimary:not(:disabled):hover {
  background-color: #0069d9;
  border-color: #0069d9;
}

.variantOutlinePrimary {
  color: #007bff;
  border-color: #007bff;
}

.variantOutlinePrimary:not(:disabled):hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.variantSecondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.variantSecondary:not(:disabled):hover {
  background-color: #5a6268;
  border-color: #545b62;
}

.variantOutlineSecondary {
  color: #6c757d;
  border-color: #6c757d;
}

.variantOutlineSecondary:not(:disabled):hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
