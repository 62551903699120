.center {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  text-align: center;
}

.loader i {
  font-size: 100px;
}

.text {
  font-size: 32px;
  margin: 0;
}
