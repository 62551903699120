.ql-editor {
  height: auto;
}

@media only screen and (max-width : 768px) {
  .ql-editor {
    padding-left: 0;
    padding-right: 0;
  }
}

.ql-editor.ql-blank::before {
  font-style: normal;
  font-size: 14px;
  color: #ccc;
}

.ql-container {
  height: initial;
}

.ql-toolbar.ql-snow .ql-formats {
  margin: 0;
}

.ql-formats:not(:last-child) {
  border-right: 1px solid #ccc;
}

.ql-formats:not(:first-child) {
  padding: 0 10px;
}

.ql-formats:first-child {
  padding-right: 10px;
}

.ql-container .ql-editor {
  min-height: 200px;
  max-height: 500px;
  resize: vertical;
  overflow: auto;
  font-size: 1rem;
  line-height: 24px;
  background: rgba(144, 144, 144, 0.075);
}

@media only screen and (max-width : 768px) {
  .ql-container .ql-editor {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.ql-editor sub,
.ql-editor sup {
  font-size: 12px;
}
