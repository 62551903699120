.headerWrapper {
  margin-bottom: 24px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  z-index: 1;
  position: relative;
}

.header {
  padding: 24px 15px;
  background-color: #fff;
}

.headerLine {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.headerLine + .headerLine {
  margin-top: 24px;
}

@media (min-width: 376px) {
  .headerLine + .headerLine {
    margin-top: 8px;
  }
}

.logo {
  flex: 1 0 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  order: 0;
  overflow: hidden;
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 100%;
  order: 2;
}

.right {
  flex: 0 0 100%;
  display: flex;
  order: 0;
  justify-content: center;
}

.left {
  flex: 100% 0 0;
  order: 1;
  display: flex;
  justify-content: center;
}


@media (min-width: 375px) {
  .logo {
    flex: 0 0 50%;
  }

  .right {
    flex: 0 0 50%;
    justify-content: flex-end;
  }
}

@media (min-width: 768px) {
  .logo {
    flex: 0 0 33%;
  }

  .left {
    flex: 0 0 33%;
    order: 0;
  }

  .right {
    flex: 0 0 33%;
    order: 1;
  }
}

@media (min-width: 1200px) {
  .logo {
    flex: 0 0 16%;
  }

  .left {
    flex: 0 0 10%;
    order: 0;
  }

  .right {
    flex: 0 0 26%;
    order: 0;
  }

  .title {
    flex: 0 0 48%;
    order: 0;
  }
}

.backButton {
  padding: 0 15px;
}

.iconBack {
  color: #000;
  font-size: 2em;
}
