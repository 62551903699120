.addProject {
  background: #f1f1f1;
  min-height: 100vh;
  padding-bottom: 24px;
}

.pageContainer {
  display: flex;
  justify-content: center;
  margin: 0 15px;
}

.formWrapper {
  background-color: rgba(255, 255, 255, 0.69);
  border-radius: 5px;
  padding: 15px;
  width: 100%;
  max-width: 1110px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.label {
  margin-bottom: 4px;
}

.actionsWrapper {
  display: flex;
  flex: 100% 0 0;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.actionButton {
  margin-right: 24px;
  margin-bottom: 0;
}

@media screen and (max-width: 480px) {
  .actionButton {
    flex: 100% 0 0;
    margin-right: 0;
    margin-bottom: 24px;
  }
}

.actionText {
  margin-left: 4px;
}

.titleContainer {
  flex: 100% 0 0;
  margin-bottom: 24px;
}

.fieldWrapper {
  flex: 100% 0 0;
  margin-bottom: 24px;
}

@media (min-width: 480px) {
  .fieldWrapper {
    flex: calc(50% - 15px) 0 0;
  }
}
