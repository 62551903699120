.performanceItem {
  position: relative;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 24px;
  padding: 15px;
}

.label {
  margin-bottom: 4px;
}

.textarea {
  min-height: 220px;
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.actionsWrapper {
  margin-top: 28px;
  text-align: right;
}

@media only screen and (max-width : 767px) {
  .actionsWrapper {
    margin: 0;
  }
}

.titleInput {
  margin-right: 24px;
}

.actionButton + .actionButton {
  margin-top: 24px;
  margin-left: 0;
}

@media only screen and (min-width : 480px) {
  .actionButton + .actionButton {
    margin-top: 0;
    margin-left: 24px;
  }
}

.performance {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 22px;
}

.value {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
}

.title,
.valueText {
  flex: 0 0 100%;
}

.titleActions,
.valueActions {
  flex: 0 0 100%;
}

@media (min-width: 768px) {
  .title,
  .valueText {
    flex: 0 0 75%;
  }

  .titleActions,
  .valueActions {
    flex: 0 0 25%;
  }
}

@media (min-width: 992px) {
  .title,
  .valueText {
    flex: 0 0 83%;
  }

  .titleActions,
  .valueActions {
    flex: 0 0 17%;
  }
}

.sectionHeading {
  display: flex;
  flex-wrap: wrap;
  padding: 22px 15px;
  align-items: flex-start;
}

.sectionTitle {
  font-size: 1.5rem;
  line-height: 32px;
  flex: 0 0 100%;
  margin-bottom: 22px;
}

@media (min-width: 576px) {
  .sectionTitle {
    margin-bottom: 0;
    flex: 0 0 75%;
  }
}

.sectionActions {
  flex: 0 0 100%;
  display: flex;
  justify-content: flex-end;
}

@media (min-width: 576px) {
  .sectionActions {
    flex: 0 0 25%;
  }
}

.wrapper {
  padding: 15px;
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
}
