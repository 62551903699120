.groupSelector {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.attrSelector {
  margin-bottom: 24px;
}

.selectWrapper {
  min-width: 290px;
  width: 100%;
  padding: 3%;
  background-color: rgba(181, 181, 181, 0.5);
  border-radius: 15px;
  color: #000;
  text-align: center;
}

.multiSelect {
  min-height: 150px;
}

.selectTitle {
  text-align: left;
  margin-bottom: 10px;
}

.groupSelector .select {
  border-color: #000;
}

.checkbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.checkbox input[type='checkbox'] {
  appearance: auto;
  z-index: 1;
  opacity: 1;
  margin: 0;
  width: 20px;
  height: 20px;
}

.checkbox .label {
  margin-left: 20px;
  text-align: left;
}

.loader {
  min-width: 290px;
  background-color: rgba(181, 181, 181, 0.5);
  border-radius: 15px;
  color: #fff;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 362px;
}
