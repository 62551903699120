.uploadsCard {
  padding: 15px;
  background-color: rgba(255, 255, 255, 0.69);
  border-radius: 5px;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.uploaderLabel {
  display: inline-block;
  margin: 0;
  padding: 0;
}

.uploaderLabelText {
  display: none;
}

.uploadData {
  font-size: 10px;
  margin-top: 12px;
}

.uploadItem {
  margin-bottom: 0;
}

.uploadItemTitle {
  margin-right: 4px;
  font-weight: 900;
}

.description {
  margin-bottom: 8px;
}

.smallDescription {
  font-size: 10px;
}

.action {
  margin-top: 12px;
}

.actionText {
  margin-left: 4px;
}

.sectionHeading {
  display: flex;
  flex-wrap: wrap;
  padding: 22px 15px;
  align-items: flex-start;
}

.sectionTitle {
  font-size: 1.5rem;
  line-height: 32px;
  flex: 0 0 100%;
  margin-bottom: 22px;
}

.wrapper {
  padding: 15px;
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
}

.uploadForm {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.files {
  flex: 0 0 100%;
}

.uploadFile {
  margin-bottom: 12px;
  padding-bottom: 18px;
  border-bottom: 1px solid #a9a9a9;
}

.uploadField {
  border-radius: 15px;
  background-color: #c7c7c7;
  padding: 40px;
  max-width: 100%;
  flex: 0 0 100%;
}

@media (min-width: 768px) {
  .files {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .uploadField {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 992px) {
  .files {
    flex: 0 0 60%;
    max-width: 60%;
  }

  .uploadField {
    flex: 0 0 40%;
    max-width: 40%;
  }
}
