.statusPicker {
  list-style: none;
  margin: 0;
  padding: 0;
}

.statusIcon {
  display: inline-block;
  margin: 0;
  padding: 0;
}
