.editorWrapper {
  background-color: #f1f1f1;
  min-height: calc(100vh - 104px);
  padding-bottom: 24px;
  margin-top: -24px;
}

.editorSections {
  display: flex;
  flex-wrap: wrap;
}

.editorSectionButton {
  padding: 18px;
  flex: 1 0;
  min-width: 240px;
  min-height: 112px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.editorSectionButton:nth-child(2) {
  background: #818181;
}

.editorSectionButton:nth-child(3) {
  background: #999;
}

.editorSectionButton:nth-child(4) {
  background: #a6a6a6;
}

.editorSectionButton:nth-child(5) {
  background: #b2b2b2;
}

.editorSectionButton:nth-child(6) {
  background: #c4c4c4;
}

.editorSectionButton:nth-child(7) {
  background: #ddd;
}

.editorSectionIcon,
.editorSectionIconPlus {
  margin-left: 20px;
  cursor: pointer;
  font-size: 22px;
}

.editorSectionIconPlus {
  font-size: 2rem;
}

@media screen and (max-width: 480px) {
  .editorSectionIconPlus {
    margin-left: 0;
  }

  .addSection {
    justify-content: center;
  }
}

.publish {
  margin: 22px 15px;
}
