.actionsWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  flex-wrap: wrap;
}

.actionButton {
  width: 100%;
  margin-bottom: 12px;
}

@media (min-width: 576px) {
  .actionButton {
    width: auto;
    margin-bottom: 0;
  }

  .actionButton + .actionButton {
    margin-left: 24px;
  }
}

.actionText {
  margin-left: 4px;
}
