.keyValue {
  background-color: #fff;
  margin-bottom: 10px;
  padding: 15px;
}

.keyValueOld {
  border: dashed 1px #dc3545;
  border-radius: 4px;
  position: relative;
  display: block;
}

.keyValueOldWarning {
  display: inline-block;
  color: #fff;
  background: #dc3545;
  padding: 2px 10px;
  border-radius: 4px;
  margin-bottom: 10px;
}

.valueDropOld {
  margin-top: 10px;
  display: flex;
  gap: 10px;
}

.valueDropOldStatus {
  flex-grow: 0;
}

.valueDropOldValue {
  flex-grow: 1;
}

.deleteWrapper {
  margin-bottom: 10px;
}

@media screen and (min-width: 736px) {
  .deleteWrapper {
    position: absolute;
    right: 15px;
    top: 5px;
  }
}

@media screen and (min-width: 1024px) {
  .deleteWrapper {
    position: absolute;
    right: 15px;
    top: 5px;
  }

  .keyValueNew {
    display: flex;
    gap: 10px;
  }
}

.keyValueDate {
  flex: 0 0 320px;
}

.keyValueValue {
  flex: 2 0 200px;
}

.label {
  margin-bottom: 5px;
}

.delete {
  padding-top: 24px;
  text-align: right;
}

.selectedStatusIcon {
  width: 44px;
  height: 44px;
  cursor: pointer;
}

.selectedStatusIconDisabled {
  cursor: default;
}

.legend {
  margin-bottom: 10px;
  color: #666;
}

.sectionHeading {
  display: flex;
  flex-wrap: wrap;
  padding: 22px 15px;
  align-items: flex-start;
}

.sectionTitle {
  font-size: 1.5rem;
  line-height: 32px;
  flex: 0 0 100%;
  margin-bottom: 22px;
}

@media (min-width: 576px) {
  .sectionTitle {
    margin-bottom: 0;
    flex: 0 0 75%;
  }
}

.sectionActions {
  flex: 0 0 100%;
  display: flex;
  justify-content: flex-end;
}

@media (min-width: 576px) {
  .sectionActions {
    flex: 0 0 25%;
  }
}

.wrapper {
  padding: 15px;
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
}

.status {
  width: 100%;
}
