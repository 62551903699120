.keyValues {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
  margin-bottom: 24px;
}

@media screen and (max-width: 768px) {
  .keyValues {
    grid-template-columns: 1fr;
    grid-gap: 15px;
  }
}

.keyValueCard {
  border-radius: 5px;
  padding: 1em 1em 1em 2em;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

@media screen and (max-width: 480px) {
  .keyValueCard {
    padding: 1em;
  }
}

.keyValueCard:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.keyValueTitle {
  font-weight: bold;
  margin-bottom: 15px;
  font-size: 1.4rem;
}

@media screen and (max-width: 480px) {
  .keyValueTitle {
    margin-bottom: 8px;
  }
}

.keyValueTextWrapper {
  padding-left: 1em;
}

@media screen and (max-width: 480px) {
  .keyValueTextWrapper {
    padding-left: 0.5em;
  }
}

.keyValueIcon {
  width: 30px;
  height: 30px;
  margin-right: 4px;
}

.legend {
  margin-bottom: 10px;
  color: #666;
}

@media print {
  .legend {
    display: none;
  }

  .keyValueCard {
    box-shadow: none;
    border: solid 1px #999;
    page-break-inside: avoid;
  }
}
