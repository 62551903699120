.card {
  border-radius: 5px;
  padding: 1em 1em 1em 3em;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

@media screen and (max-width: 480px) {
  .card {
    padding: 1em;
  }
}

.card:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.card:not(:last-child) {
  margin-bottom: 24px;
}

.cardTitle {
  font-weight: bold;
  margin-bottom: 15px;
  font-size: 1.4rem;
}

@media screen and (max-width: 480px) {
  .cardTitle {
    margin-bottom: 8px;
  }
}

.cardTextWrapper {
  padding-left: 1em;
}

@media screen and (max-width: 480px) {
  .cardTextWrapper {
    padding-left: 0.5em;
  }
}

.cardText {
  font-size: 1rem;
  margin-bottom: 0;
}
