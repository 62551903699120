.menu {
  position: relative;
}

.menuButton {
  background-color: transparent;
  padding: 0 16px;
  display: flex;
  align-items: center;
}

.menuButton:focus {
  outline: none;
}

.menuIcon {
  font-size: 24px;
}

.menuItems {
  position: absolute;
  background-color: #fff;
  top: 40px;
  right: 0;
  z-index: 1;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  overflow: hidden;
}

.dotsMenuItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 12px;
  background-color: transparent;
  text-transform: uppercase;
  line-height: normal;
  min-height: 42px;
  color: #000;
  width: 100%;
  text-align: left;
  border-radius: 0;
  font-size: 1em;
}

.dotsMenuItem:hover {
  background-color: #808080;
  color: #fff;
}

.dotsMenuItem i {
  margin-right: 8px;
}

.exportIcon {
  width: 14px;
  height: 14px;
  margin: 0 8px 0 0;
}

.exportCheckbox {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  cursor: pointer;
}

.exportCheckbox label {
  cursor: pointer;
  flex-grow: 1;
}

.divider {
  display: block;
  height: 1px;
  background: #dddddd;
  margin: 8px 0;
}
