.wrapper {
  position: relative;
}

.wrapper .input {
  padding-right: 39px;
}

.button {
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: transparent;
  border: none;
  padding: 0;
  height: auto;
  line-height: normal;
  width: auto;
}

.icon {
  color: #007bff;
  font-size: 20px;
}

.calendar {
  position: absolute;
  transform: translateX(-50%);
  margin-left: 50%;
  top: 40px;
  background-color: #fff;
  z-index: 1;
  border: 1px solid rgba(144, 144, 144, 0.25);
}
