.tag {
  background: grey;
  display: inline-block;
  border-radius: 15px;
  padding: 0 10px;
  margin-right: 10px;
  color: #fff;
}

.active {
  cursor: pointer;
}

.red {
  background: red;
}

.green {
  background: green;
}
