.popupWrapper {
  padding: 0 25px 25px;
  text-align: center;
}

.headline {
  text-align: center;
}

.catch {
  font-size: 1.5rem;
  text-align: center;
  padding: 40px 0 20px;
  overflow: hidden;
}

.actions {
  margin: 20px 0;
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}
