.uploads {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
}

@media screen and (max-width: 768px) {
  .uploads {
    grid-template-columns: 1fr;
  }
}

.uploadsCard {
  border-radius: 5px;
  padding: 1em;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.uploadsCard:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.uploadData {
  font-size: 10px;
  margin-top: 12px;
}

.uploadItem {
  margin-bottom: 0;
}

.uploadItemTitle {
  margin-right: 4px;
  font-weight: 900;
}
