.wrapper {
}

.actions {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

@media screen and (min-width: 736px) {
  .actions {
    flex-direction: row;
    justify-content: flex-end;
  }
}

.action {
  margin-top: 10px;
}

.actionIcon {
  margin-right: 4px;
}

@media screen and (min-width: 736px) {
  .action + .action {
    margin-left: 10px;
  }
}
