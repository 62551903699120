.calendarPage {
  min-height: 100vh;
  width: 100vw;
  background: #000 url('/src/images/background2.jpg') center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(14, 14, 14, .73);
}

.calendar {
  min-width: 290px;
  width: 100%;
  padding: 3%;
  background-color: rgba(181, 181, 181, 0.5);
  border-radius: 15px;
  color: #000;
  text-align: center;
}

.dates {
  margin: 20px 0;
  color: #fff;
}

.calendar :global(.DayPicker-Weekday),
.calendar :global(.DayPicker-WeekNumber) {
  color: #fff;
}

.calendarPage :global(.DayPicker-NavButton--prev),
.calendarPage :global(.DayPicker-NavButton--next) {
  filter: brightness(2);
}

.wrapper {
  padding: 100px 15px;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  justify-content: center;
}

.column {
  display: flex;
  flex: 100% 0 0;
  margin-bottom: 24px;
}

@media (min-width: 376px) {
  .wrapper {
    justify-content: space-between;
  }

  .column {
    flex: calc(50% - 15px) 0 0;
  }
}
