.logo {
  position: fixed;
  top: 25px;
  left: 25px;
  z-index: 1;
}

.link:hover {
  text-decoration: none;
}

.link:hover img {
  -webkit-filter: drop-shadow(2px 2px 2px #222);
  filter: drop-shadow(2px 2px 2px #222);
}
