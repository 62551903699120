
.highlightWrapper {
  position: relative;
  padding: 0 0 20px;
  margin-bottom: 24px;
}

.expandableWrapper {
  overflow: hidden;
}

.toggleButtonWrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 60px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0.7) 75%, rgba(255, 255, 255, 0) 100%);
}

@media print {
  .toggleButtonWrapper {
    display: none;
  }
}

.toggleButtonWrapperExpanded {
  height: 20px;
  background: none;
}

.toggleButton {
  background-color: #808080;
  border-radius: 15px;
  padding: 3px 15px;
  cursor: pointer;
  font-size: x-small;
  color: #fff;
}

.toggleButtonText {
  margin-left: 3px;
}
