.reportsPage {
  background: #f1f1f1;
  min-height: 100vh;
}

.myReport {
  margin-right: 22px;
}

.activeMyReport i,
.activeMyReport span {
  color: #fff;
}

.buttonText {
  font-size: 10px;
  margin-left: 4px;
}

.buttonsWrapper {
  display: flex;
  justify-content: space-between;
  flex: 0 0 75%;
  max-width: 75%;
}


@media (min-width: 576px) {
  .buttonsWrapper {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 768px) {
  .buttonsWrapper {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}

@media (min-width: 992px) {
  .buttonsWrapper {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

.buttonSuccessPrimary,
.buttonSuccess,
.myReport {
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.report {
  margin-bottom: 24px;
  background-color: #fff;
  border-radius: 5px;
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
}

.circleStatus {
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.reportTitle {
  font-size: 1.5rem;
  font-weight: 900;
}

.reportSubTitle {
  font-size: 1.2rem;
}

@media only screen and (max-width : 768px) {
  .reportSubTitle {
    font-size: 1rem;
  }
}

.chipsWrapper {
  display: inline-block;
  margin-left: 10px;
}

@media only screen and (max-width : 768px) {
  .chipsWrapper {
    display: block;
    padding-top: 12px;
  }
}

.metaItem {
  line-height: 1.2;
}

.metaTitle,
.metaData {
  font-size: 10px;
}

.metaTitle {
  font-weight: bold;
  margin-right: 4px;
}

.metaData {
  margin-right: 8px;
}

.metaItemWrapper {
  display: inline-block;
}

@media only screen and (max-width : 768px) {
  .metaItemWrapper {
    display: block;
  }
}

.noReportsWrapper {
  display: flex;
  justify-content: center;
}

.search {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

@media (min-width: 501px) {
  .search {
    flex-wrap: nowrap;
  }
}

.actions {
  display: flex;
  flex: 100% 0 0;
  margin-top: 24px;
  justify-content: center;
}

@media (min-width: 501px) {
  .actions {
    flex-basis: auto;
    margin: 0;
  }
}

.inputTitle {
  display: none;
  padding: 8px 10px;
}

@media (min-width: 768px) {
  .inputTitle {
    display: block;
  }
}

.inputWrapper {
  flex: 0 0 100%;
}

@media (min-width: 501px) {
  .inputWrapper {
    flex: 0 1 60%;
    margin-right: 30px;
  }
}

.reportsWrapper {
  padding: 0 15px;
}

.reportStatus {
  flex: 0 0 25%;
  max-width: 25%;
}

@media (min-width: 576px) {
  .reportStatus {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 768px) {
  .reportStatus {
    flex: 0 0 66.667%;
    max-width: 66.667%;
  }
}

@media (min-width: 992px) {
  .reportStatus {
    flex: 0 0 8.333%;
    max-width: 8.333%;
  }
}

.reportInfo {
  flex: 0 0 100%;
  max-width: 100%;
  order: 2;
  margin-top: 24px;
}

@media (min-width: 768px) {
  .reportInfo {
    flex: 0 0 66.667%;
    max-width: 66.667%;
    margin-top: 0;
  }
}

@media (min-width: 992px) {
  .reportInfo {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
    order: 0;
  }
}


