.prevButton,
.nextButton {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: transparent;
  padding: 0;
  font-size: 24px;
}

.prevButton:hover,
.nextButton:hover {
  -webkit-filter: drop-shadow(2px 2px 2px #222);
  filter: drop-shadow(2px 2px 2px #222);
}

.prevButton:focus,
.nextButton:focus {
  outline: none;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.exportActions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.exportActions {
  margin-right: 8px;
}

.exportActions label {
  margin: 0;
  text-align: center;
}

.exportActions label,
.exportActions input {
  cursor: pointer;
}

.exportIcon {
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav {
  display: flex;
  align-items: center;
}
