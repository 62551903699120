.resultItem {
  position: relative;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 24px;
  padding: 15px;
}

.label {
  margin-bottom: 4px;
}

.itemWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

@media only screen and (min-width : 767px) {
  .itemWrapper {
    flex-wrap: nowrap;
  }
}

.actionsWrapper {
  margin-top: 28px;
  text-align: right;
}

@media only screen and (max-width : 767px) {
  .actionsWrapper {
    margin: 0;
  }
}

.actionButton + .actionButton {
  margin-top: 24px;
}

@media (min-width : 480px) {
  .actionButton + .actionButton {
    margin-left: 24px;
    margin-top: 0;
    min-width: 38px;
  }
}

.addQueryWrapper {
  position: relative;
}

.queryWarning {
  margin: 0 8px;
  color: grey;
}

.queryModalButton {
  border: 0;
  background: none;
  color: grey;
  padding: 0;
  margin: 0;
  height: auto;
  line-height: 1;
  width: auto;
}

.importHelpButton {
  border: solid 1px grey;
  color: grey;
  border-radius: 15px;
  padding: 0;
  height: 30px;
  width: 30px;
  line-height: 30px;
  vertical-align: middle;
  margin-right: 15px;
}

.import {
  display: flex;
  align-items: center;
  margin-right: 24px;
}

.sectionHeading {
  display: flex;
  flex-wrap: wrap;
  padding: 22px 15px;
  align-items: flex-start;
}

.sectionTitle {
  font-size: 1.5rem;
  line-height: 32px;
  flex: 0 0 100%;
  margin-bottom: 22px;
}

@media (min-width: 768px) {
  .sectionTitle {
    margin-bottom: 0;
    flex: 0 0 50%;
  }
}

.sectionActions {
  flex: 0 0 100%;
  display: flex;
  justify-content: flex-end;
}

@media (min-width: 768px) {
  .sectionActions {
    flex: 0 0 50%;
  }
}

.wrapper {
  padding: 15px;
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
}

.resultRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.result {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 22px;
}

.resultTitle {
  flex: 0 0 100%;
}

.resultActions {
  flex: 0 0 100%;
}

@media (min-width: 768px) {
  .resultTitle {
    flex: 0 0 75%;
  }

  .resultActions {
    flex: 0 0 25%;
  }
}

@media (min-width: 992px) {
  .resultTitle {
    flex: 0 0 83%;
  }

  .resultActions {
    flex: 0 0 17%;
  }
}

.keyWrapper,
.weekWrapper,
.targetWrapper,
.lastweekWrapper,
.rowActions,
.queryWrapper {
  flex: 0 0 100%;
  margin-bottom: 8px;
}

.queryActionsWrapper {
  flex: 0 0 100%;
  margin-bottom: 70px;
}

@media (min-width: 768px) {
  .keyWrapper {
    flex: 0 0 49%;
  }

  .weekWrapper {
    flex: 0 0 49%;
  }

  .targetWrapper,
  .lastweekWrapper {
    flex: 0 0 49%;
  }

  .queryWrapper {
    flex: 0 0 74%;
  }

  .queryActionsWrapper {
    flex: 0 0 24%;
  }
}

@media (min-width: 992px) {
  .keyWrapper {
    flex: 0 0 32%;
    margin-bottom: 0;
  }

  .weekWrapper {
    flex: 0 0 24%;
    margin-bottom: 0;
  }

  .targetWrapper,
  .lastweekWrapper {
    flex: 0 0 16%;
    margin-bottom: 0;
  }

  .rowActions {
    flex: 0 0 8%;
    margin-bottom: 0;
  }

  .queryWrapper {
    flex: 0 0 50%;
    margin-bottom: 0;
  }

  .queryActionsWrapper {
    flex: 0 0 16%;
    margin-bottom: 0;
  }
}
