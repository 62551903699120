.results {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
}

@media screen and (max-width: 768px) {
  .results {
    grid-template-columns: 1fr;
    grid-gap: 15px;
  }
}

.resultsCard {
  border-radius: 5px;
  padding: 1em;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.resultsCard:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.resultsTitle {
  text-align: center;
  font-size: 1.4rem;
  margin-bottom: 8px;
}

@media screen and (max-width: 480px) {
  .resultsTitle {
    padding-left: 0;
    padding-right: 0;
  }
}

.tableDateRow td {
  font-size: 1rem;
}

@media print {
  .results {
    grid-template-columns: 1fr;
    grid-gap: 15px;
  }

  .resultsCard {
    box-shadow: none;
    border: solid 1px #999;
  }
}
