.highlight {
  padding: 24px 15px;
  border-bottom: 1px solid #a9a9a9;
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

@media screen and (min-width: 768px) {
  .highlight {
    flex-wrap: nowrap;
  }
}

.circleStatus {
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

.toggleButtonText {
  margin-left: 3px;
}

.icon {
  color: #000;
  margin-right: 5px;
}

.reportDataSection {
  margin-bottom: 4px;
}

.reportDataTitle {
  margin-right: 4px;
  font-weight: 900;
}

.weekHighlightWrapper {
  margin-bottom: 24px;
}

.weekHighlight {
  margin-bottom: 8px;
  font-size: 18px;
}

.weekHighlight i {
  margin-right: 4px;
}

.inputWrapper form {
  display: flex;
  align-items: center;
}

.inputActions {
  margin-left: 12px;
  display: flex;
}

.editButton,
.submitButton,
.cancelButton {
  background-color: transparent;
  padding: 0;
  margin-left: 8px;
  width: auto;
}

.icon {
  font-size: 18px;
}

.iconSave {
  color: #28a745;
}

.iconCancel {
  color: #dc3545;
}

.iconEdit {
  color: #007bff;
}

@media print {
  .iconEdit {
    display: none;
  }
}

.highlightText {
  display: flex;
  align-items: center;
}

.status {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .status {
    width: 60px;
  }
}

.wrapper {
  width: 100%;
  order: 2;
}

@media screen and (min-width: 768px) {
  .wrapper {
    order: 0;
    width: auto;
    flex-grow: 1;
  }
}

.data {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .data {
    width: 25%;
    flex: 0 0 25%;
  }
}
