body, html {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif !important;
  margin: 0;
  padding: 0;
}

ol {
  margin: 0;
  padding-left: 1.25rem;
}

ul {
  margin: 0;
  padding-left: 1.25rem;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  vertical-align: middle;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

textarea {
  font-family: inherit;
}
