.wrapper {
  position: relative;
  height: 100vh;
  background-color: #222;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notfound {
  max-width: 460px;
  text-align: center;
}

.notfound404 {
  color: #222;
  font-size: 220px;
  line-height: 250px;
  letter-spacing: 10px;
  font-weight: 700;
  text-shadow: 2px 2px 0 #c9c9c9, -2px -2px 0 #c9c9c9;
}

.notfound .notfound404 span {
  text-shadow: 2px 2px 0 #ffab00, -2px -2px 0 #ffab00, 0 0 8px #ff8700;
}

.notfound p {
  color: #c9c9c9;
  font-size: 16px;
  font-weight: 400;
}

.link {
  font-size: 14px;
  text-decoration: none;
  text-transform: uppercase;
  background: transparent;
  color: #c9c9c9;
  border: 2px solid #c9c9c9;
  display: inline-block;
  padding: 10px 25px;
  font-weight: 700;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.link:hover {
  color: #ffab00;
  border-color: #ffab00;
  text-decoration: none;
}

@media only screen and (max-width: 480px) {
  .notfound .notfound404 {
    height: 122px;
    line-height: 122px;
  }

  .notfound .notfound404 h1 {
    font-size: 122px;
  }
}
