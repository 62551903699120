.status {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.label {
  display: inline-block;
  position: relative;
  margin-bottom: 0;
}

.label + .label {
  margin-left: 15px;
}

.customRadio {
  display: inline-block;
  height: 40px;
  width: 40px;
  border: solid 4px;
  border-radius: 50%;
  box-sizing: border-box;
  transition: border-width .1s linear;
}

.customRadio:hover {
  cursor: pointer;
  border-width: 8px;
}
