*,
*:before,
*:after {
  box-sizing: border-box;
}

body,
input,
select,
textarea {
  font-size: 1rem;
  line-height: 1.65;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

strong,
b {
  font-weight: 600;
}

em,
i {
  font-style: italic;
}

p {
  margin: 0 0 0.5em 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.5;
  margin: 0;
}

h2 {
  font-size: 1.75rem;
}

h3 {
  font-size: 1.35rem;
}

h4 {
  font-size: 1.1rem;
}

h5 {
  font-size: 0.9rem;
}

h6 {
  font-size: 0.7rem;
}

/* Button */

input[type='submit'],
input[type='reset'],
input[type='button'],
button,
.button {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  -moz-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  -ms-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  border-radius: 2px;
  border: 0;
  cursor: pointer;
  display: inline-block;
  line-height: 2.95rem;
  padding: 0 1.5rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
}

input[type='submit'].disabled,
input[type='submit']:disabled,
input[type='reset'].disabled,
input[type='reset']:disabled,
input[type='button'].disabled,
input[type='button']:disabled,
button.disabled,
button:disabled,
.button.disabled,
.button:disabled {
  -moz-pointer-events: none;
  -webkit-pointer-events: none;
  -ms-pointer-events: none;
  pointer-events: none;
  opacity: 0.25;
}

@media screen and (max-width: 480px) {
  input[type='submit'],
  input[type='reset'],
  input[type='button'],
  button {
    padding: 0;
    width: 100%;
  }
}

input[type='submit'],
input[type='reset'],
input[type='button'],
button {
  background-color: #f2f2f2;
  color: #000;
}

input[type='submit']:hover,
input[type='reset']:hover,
input[type='button']:hover,
button:hover {
  background-color: white;
}

input[type='submit']:active,
input[type='reset']:active,
input[type='button']:active,
button:active {
  background-color: #e5e5e5;
}
/* Form */


input[type='text'],
input[type='password'],
input[type='email'],
select,
textarea {
  border-radius: 2px;
  border: solid 1px;
  display: block;
  padding: 0 8px;
  text-decoration: none;
  width: 100%;
}

input[type='text'],
input[type='password'],
input[type='email'],
select,
textarea {
  height: 2.75rem;
}

input:-webkit-autofill {
  -webkit-text-fill-color: inherit;
}

input[type='text'],
input[type='password'],
input[type='email'],
select,
textarea {
  background: rgba(144, 144, 144, 0.075);
  border-color: rgba(144, 144, 144, 0.25);
}

input[type='checkbox'] + label:before,
input[type='radio'] + label:before {
  background: rgba(144, 144, 144, 0.075);
  border-color: rgba(144, 144, 144, 0.25);
}

input[type='checkbox']:checked + label:before,
input[type='radio']:checked + label:before {
  background-color: #8a4680;
  border-color: #8a4680;
  color: #ffffff;
}

input[type='checkbox']:focus + label:before,
input[type='radio']:focus + label:before {
  border-color: #8a4680;
  box-shadow: 0 0 0 1px #8a4680;
}

::-webkit-input-placeholder {
  color: #bbb !important;
}

:-moz-placeholder {
  color: #bbb !important;
}

::-moz-placeholder {
  color: #bbb !important;
}

:-ms-input-placeholder {
  color: #bbb !important;
}

/* Icon */

.icon {
  text-decoration: none;
  border-bottom: none;
  position: relative;
}

.icon:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: normal;
  text-transform: none !important;
}

.icon > .label {
  display: none;
}

/* List */

ol {
  list-style: decimal;
  margin: 0 0 2rem 0;
  padding-left: 1.25rem;
}

ol li {
  padding-left: 0.25rem;
}

ul {
  list-style: disc;
  margin: 0;
  padding-left: 1rem;
}

ul li {
  padding-left: 0.5rem;
}

/* Table */

.table-wrapper {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

table {
  margin: 0 0 2rem 0;
  width: 100%;
  border-collapse: collapse;
}

table tbody tr {
  border: solid 1px;
  border-left: 0;
  border-right: 0;
}

table td {
  padding: 0.75rem 0.75rem;
}

table th {
  font-size: 0.9rem;
  font-weight: 700;
  padding: 0 0.75rem 0.75rem 0.75rem;
  text-align: left;
}

table thead {
  border-bottom: solid 2px;
}

table tfoot {
  border-top: solid 2px;
}

table tbody tr {
  border-color: rgba(144, 144, 144, 0.25);
}

table tbody tr:nth-child(2n + 1) {
  background-color: rgba(144, 144, 144, 0.075);
}

table thead {
  border-bottom-color: rgba(144, 144, 144, 0.25);
}

table tfoot {
  border-top-color: rgba(144, 144, 144, 0.25);
}

table.alt tbody tr td {
  border-color: rgba(144, 144, 144, 0.25);
}

h1,
h2,
h3,
p,
td,
blockquote,
input {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-variant: normal;
}

/* Global Button Styles */
.animated-button {
  background: none;
  position: relative;
  display: block;
  margin: 30px auto 0;
  padding: 14px 15px;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  height: auto;
  line-height: normal;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  letter-spacing: 0.08em;
  border-radius: 0;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.2), 0 1px 0 rgba(0, 0, 0, 0.2);
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
  z-index: 0;
}

.animated-button:after {
  content: '';
  position: absolute;
  height: 0;
  left: 50%;
  top: 50%;
  width: 150%;
  z-index: -1;
  -webkit-transition: all 0.75s ease 0s;
  -moz-transition: all 0.75s ease 0s;
  -o-transition: all 0.75s ease 0s;
  transition: all 0.75s ease 0s;
}

.animated-button:hover {
  color: #fff;
  background: none;
  text-shadow: none;
}

.animated-button:hover:after {
  height: 450%;
}

/* Victoria Buttons */

.animated-button.victoria-one {
  min-width: 260px;
  max-width: 270px;
  width: 100%;
  border: 2px solid #d2590f;
}

.animated-button.victoria-one:after {
  background: #d2590f;
  -moz-transform: translateX(-50%) translateY(-50%) rotate(-25deg);
  -ms-transform: translateX(-50%) translateY(-50%) rotate(-25deg);
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(-25deg);
  transform: translateX(-50%) translateY(-50%) rotate(-25deg);
}

/* DayPicker */
.DayPicker-Day:focus {
  outline: 0;
}

.DayPicker-Day {
  border-radius: 0;
}

.DayPicker-Day--today {
  color: #d2590f;
}

.DayPicker-Day--hoverRange:not(:hover) {
  background-color: rgba(74, 144, 226, 0.5);
  color: #F0F8FF;
}

@media screen and (max-width: 991px) {
  .DayPicker {
    font-size: 0.9rem;
  }

  .DayPicker-Month {
    margin-right: 0;
    margin-left: 0;
  }
}

html,
body {
  font-size: 14.6px;
  max-width: 100%;
  overflow-x: hidden;
}
