.label {
  margin-bottom: 4px;
}

.riskItem {
  position: relative;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 24px;
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.actionText {
  margin-left: 4px;
}

.helpButton {
  width: 100%;
}

.sectionHeading {
  display: flex;
  flex-wrap: wrap;
  padding: 22px 15px;
  align-items: flex-start;
}

.sectionTitle {
  font-size: 1.5rem;
  line-height: 32px;
  flex: 0 0 100%;
  margin-bottom: 22px;
}

@media (min-width: 768px) {
  .sectionTitle {
    margin-bottom: 0;
    flex: 0 0 50%;
  }
}

.sectionActions {
  flex: 0 0 100%;
  display: flex;
  justify-content: flex-end;
}

@media (min-width: 768px) {
  .sectionActions {
    flex: 0 0 50%;
  }
}

.wrapper {
  padding: 15px;
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
}

.riskItemActions {
  margin-bottom: 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex: 0 0 100%;
}

.riskTitle {
  flex: 0 0 100%;
  margin-bottom: 8px;
}

.riskHelp {
  flex: 0 0 100%;
  margin-bottom: 8px;
}

.riskImpact,
.riskDueDate,
.riskOwner {
  flex: 0 0 100%;
  margin-bottom: 8px;
}

.riskDescription,
.riskMitigation {
  flex: 0 0 100%;
  margin-bottom: 8px;
}

@media (min-width: 768px) {
  .riskImpact,
  .riskDueDate,
  .riskOwner {
    flex: 0 0 32%;
  }

  .riskDescription,
  .riskMitigation {
    flex: 0 0 49%;
  }
}

@media (min-width: 992px) {
  .riskTitle {
    flex: 0 0 74%;
  }

  .riskHelp {
    flex: 0 0 24%;
  }
}
