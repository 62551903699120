.windowContainer {
  background: rgba(0, 0, 0, .25);
  bottom: 0;
  left: 0;
  outline: none;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
}

.modalContainer {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  outline: none;
}

.modal {
  background: #fff;
  height: auto;
  max-width: 100%;
  border-radius: 4px;
  padding: 20px;
}

@media screen and (min-width: 736px) {
  .modal {
    max-width: 80%;
  }
}

.modal img {
  max-width: 100%;
}
