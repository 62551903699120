.wrapper {
  padding: 5%;
}

.heading {
  margin: 0;
}

.buttonsList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.buttonWrapper {
  margin-bottom: 20px;
  width: 100%;
}

@media screen and (min-width: 736px) {
  .buttonWrapper {
    width: calc(50% - 10px)
  }
}

.button {
  width: 100%;
  height: 140px;
}
