.loginPage {
  height: 100vh;
  width: 100vw;
  background: #000 url('/src/images/background1.jpg') center;
  background-size: cover;
}

.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  background: rgba(14, 14, 14, .73);
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.buttonWrapper {
  margin-top: 30px;
}

.heading {
  color: white;
  font-size: 30px;
  font-weight: normal;
  text-align: center;
}

@media only screen and (min-width : 768px) {
  .heading {
    font-size: 35px;
  }
}
