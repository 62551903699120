.tableDataRow td {
  font-size: 1rem;
}

.circleSmallStatus {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  border: 0;
}

.helpIcon {
  font-size: 30px;
  color: #ff0000;
}

.riskTitle {
  font-weight: 900;
}

.table .riskTitleRow {
  border-bottom-color: transparent;
  background-color: transparent;
}

.table tbody tr:nth-child(4n + 1),
.table tbody tr:nth-child(4n + 2) {
  background-color: rgba(144, 144, 144, 0.075);
}

.table .riskTitleRow.isResolvedRow,
.table .tableDataRow.isResolvedRow {
  background-color: rgba(40, 167, 69, 0.4);
}
