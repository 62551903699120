.wrapper {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}

.horizontal {
  flex-direction: row;
  min-width: 85px;
  justify-content: space-between;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 375px) {
  .button {
    min-width: 42px;
  }
}

.label {
  font-size: 10px;
  margin: 0;
}
