.wrapper {
  display: block;
  width: 100%;
}

.tabsList {
  border-bottom: solid 1px #a9a9a9;
  display: flex;
  list-style: none;
  margin: 0;
  max-width: 100%;
  -ms-overflow-style: none;
  overflow-x: scroll;
  padding: 0;
  scrollbar-width: none;
}

.tabsList::-webkit-scrollbar {
  display: none;
}

.tabWrapper {
  margin: 0;
  padding: 0;
}

.tabButton {
  background: none;
  border-bottom: 4px solid;
  border-color: transparent;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  border-radius: 0;
  display: block;
  position: relative;
  padding: 0 15px;
  height: 40px;
  line-height: 40px;
}

.tabButtonSelected,
.tabButton:hover {
  border-color: #d2590f;
}

.tabButton:focus {
  outline: none;
}

.content {
  padding: 15px;
  flex-grow: 1;
}

@media screen and (min-width: 1024px) {
  .wrapper {
    display: flex;
  }

  .tabsList {
    flex-direction: column;
    flex: 0 0 200px;
    border-bottom: 0;
    border-right: solid 1px #a9a9a9;
  }

  .tabButton {
    width: 100%;
    text-align: right;
    border-bottom: 0;
    border-right: 4px solid;
    border-color: transparent;
    padding: 0 5px;
  }

  .tabButtonSelected {
    border-color: #d2590f;
  }
}
