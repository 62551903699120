.groups {
  background: #f1f1f1;
  min-height: 100vh;
  padding-bottom: 24px;
}

.header {
  padding: 24px 0;
  background-color: #fff;
  margin-bottom: 24px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
}

.groupBlock {
  min-height: 15px;
  background-color: rgba(255, 255, 255, 0.69);
  border-radius: 5px;
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 24px;
}

.groupTitle {
  font-size: 24px;
  font-weight: normal;
  margin: 12px 0 8px;
  flex: 0 0 100%;
  max-width: 100%;
}

@media (min-width: 768px) {
  .groupTitle {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
    margin-bottom: 0;
  }
}

.groupValues {
  flex: 0 0 100%;
  max-width: 100%;
}

@media (min-width: 768px) {
  .groupValues {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
}

.valueWrapper,
.addValueWrapper {
  width: 100%;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  margin-bottom: 4px;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.addValueWrapper {
  align-items: flex-start;
}

.createButtonText {
  margin-left: 4px;
}

.addingCancel {
  margin-right: 24px;
}

.addingCancel,
.addingSuccess {
  width: 50%;
}

.addingTitle {
  font-size: 24px;
  font-weight: normal;
  flex: 0 0 100%;
  margin-bottom: 8px;
}

.addingInput {
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 24px;
}

@media (min-width: 768px) {
  .addingInput {
    flex: 0 0 65%;
    max-width: 65%;
    margin-bottom: 0;
  }
}

.addingActions {
  display: flex;
  justify-content: flex-end;
  flex: 0 0 100%;
  max-width: 100%;
}

@media (min-width: 768px) {
  .addingActions {
    flex: 0 0 30%;
    max-width: 30%;
    margin-bottom: 0;
  }
}

.deleteAttrBtn,
.addingValueCancel,
.updateAttrCancel {
  margin-left: 12px;
}

.actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  flex: 0 0 100%;
}

.wrapper {
  padding: 0 15px;
  width: 100%;
  max-width: 1110px;
  margin: 0 auto;
  border-radius: 5px;
}
